import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import CheckIcon from '../utils/shared-svgs/check-icon.svg';
import ChevronDownIcon from '../utils/shared-svgs/chevron-down-icon.svg';
import ChevronUpIcon from '../utils/shared-svgs/chevron-up-icon.svg';
import { useFormProvider } from '../form-provider';
import throwOnMissing from '../utils/throw-on-missing';
import FormAlert from '../form-alert';
import CaretDown from '../utils/shared-svgs/caret-down.svg';
import FormLabel from '../form-label';
import usePersistedId from '../utils/use-persisted-id';
import { colors } from '../colors';
import { borderRadius, breakpoints } from '../tokens';

interface SelectOptionProps {
    value: string;
    children: React.ReactNode | React.ReactNode[] | string
    disabled?: boolean
}

export const SelectOption = ({
    children,
    value,
    disabled,
}: SelectOptionProps): JSX.Element => (
    <SelectPrimitive.Item
        css={{
            fontSize: '13px',
            lineHeight: '20px',
            color: colors.shadesBlack,
            borderRadius: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '6px 10px',
            position: 'relative',
            userSelect: 'none',
            outline: 'none',
            gap: '12px',
            boxShadow: '0 0 0 2px transparent',
            transition: '0.2s',
            '&:focus-visible': {
                backgroundColor: colors.grey50,
            },
            '&[data-disabled]': {
                color: colors.grey100,
                pointerEvents: 'none',
            },
            '&:hover': {
                backgroundColor: colors.grey50,
            },
        }}
        value={value}
        disabled={disabled}
        aria-disabled={disabled}
    >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
        <SelectPrimitive.ItemIndicator
            css={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CheckIcon />
        </SelectPrimitive.ItemIndicator>
    </SelectPrimitive.Item>
);

export interface SelectProps
    extends Omit<
    HtmlPropsNoRef<SelectPrimitive.SelectProps>,
    'onChange' | 'onBlur'
    > {
    onChange?(value: string): void;
    onBlur?(value: boolean): void;
    value?: string;
    children: any;
    name: string;
    bottomMargin?: string
}

const Select = ({
    children,
    onChange,
    onBlur,
    placeholder,
    name,
    required,
    disabled,
    value,
    className,
    bottomMargin,
}: SelectProps) : JSX.Element => {
    throwOnMissing(name, 'name', 'Select');

    const formContext = useFormProvider();

    if (!children) {
        // eslint-disable-next-line no-param-reassign
        children = [
            <SelectOption disabled value="NA">
                No options available.
            </SelectOption>,
        ];
    }

    if (!Array.isArray(children)) {
        // eslint-disable-next-line no-param-reassign
        children = [children];
    }

    const mappedChildren = children.map((x: any) => x.props.value);

    if (placeholder) {
        // eslint-disable-next-line no-param-reassign
        children = [
            <SelectOption disabled value={placeholder}>
                {placeholder}
            </SelectOption>,
            children,
        ].flat();
    }

    const [localValue, setLocalValue] = React.useState(!placeholder ? mappedChildren[0] : placeholder);

    const id = usePersistedId();

    React.useEffect(() => {
        if (!placeholder && formContext) {
            formContext.setValue(name, mappedChildren[0]);
        }
    }, [mappedChildren, formContext, name, placeholder]);

    React.useEffect(() => {
        if (formContext && formContext.getValues(name)) {
            if (!mappedChildren.includes(formContext.getValues(name))) {
                formContext.setValue(name, mappedChildren[0]);
                setLocalValue(mappedChildren[0]);
            }
        }
    }, [mappedChildren, formContext, name, placeholder]);

    if (formContext) {
        formContext.register(name, { required });
    }

    return (
        <div css={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            'div:nth-child(2)': {
                top: '0',
            },
        }}
        >
            <SelectPrimitive.Root
                required={required}
                onValueChange={(e) => {
                    setLocalValue(e);
                    if (onChange) {
                        onChange(e);
                    }
                    if (formContext) {
                        formContext.setValue(name, e);
                    }
                }}
                onOpenChange={(e) => {
                    if (onBlur) {
                        onBlur(e);
                    }
                    if (formContext && !e) {
                        formContext.trigger(name);
                    }
                }}
                value={value || formContext?.getValues(name) || localValue}
                name={name}
            >
                <SelectPrimitive.SelectTrigger
                    id={id}
                    data-value={(value || formContext?.getValues(name) || localValue) !== placeholder}
                    css={{
                        '&[aria-expanded="true"] ~ div label, &[data-value="true"] ~ div label': {
                            opacity: '1',
                        },
                        '&[aria-expanded="true"]': {
                            backgroundColor: 'transparent',
                        },
                        border: formContext
                        && formContext.errors
                        && formContext.errors[name] ? `2px solid ${colors.statesError}` : `2px solid ${colors.grey100}`,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        userSelect: 'none',
                        padding: '12px',
                        width: '100%',
                        fontSize: '16px',
                        backgroundColor: colors.shadesWhite,
                        color: colors.grey200,
                        borderRadius: borderRadius.medium,
                        textAlign: 'left',
                        gap: '9px',
                        '@media (max-width: 767px)': {
                            fontSize: '14px',
                        },
                    }}
                    className={className}
                    aria-label={name}
                    disabled={disabled}
                    aria-disabled={disabled}
                >
                    <SelectPrimitive.Value defaultValue={value || formContext?.getValues(name) || localValue} />
                    <SelectPrimitive.Icon>
                        <CaretDown
                            css={{
                                width: '5px',
                                [breakpoints.mb]: {
                                    width: '16px',
                                    height: '7px',
                                },
                            }}
                        />
                    </SelectPrimitive.Icon>
                </SelectPrimitive.SelectTrigger>
                {placeholder && (
                    <FormLabel id={id}>
                        {placeholder}
                    </FormLabel>
                )}
                <SelectPrimitive.Content
                    css={{
                        overflow: 'hidden',
                        backgroundColor: colors.shadesWhite,
                        color: colors.shadesBlack,
                        borderRadius: 6,
                        boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
                        zIndex: 100,
                        marginTop: bottomMargin || '48px',
                    }}
                >
                    <SelectPrimitive.SelectScrollUpButton
                        css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 25,
                            margin: '0 -1px',
                            backgroundColor: colors.shadesWhite,
                            color: colors.shadesBlack,
                            cursor: 'default',
                        }}
                    >
                        <ChevronUpIcon />
                    </SelectPrimitive.SelectScrollUpButton>
                    <SelectPrimitive.Viewport
                        css={{
                            padding: '5px',
                        }}
                    >
                        {children}
                    </SelectPrimitive.Viewport>
                    <SelectPrimitive.SelectScrollDownButton
                        css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 25,
                            margin: '0 -1px',
                            backgroundColor: colors.shadesWhite,
                            color: colors.shadesBlack,
                            cursor: 'default',
                        }}
                    >
                        <ChevronDownIcon />
                    </SelectPrimitive.SelectScrollDownButton>
                </SelectPrimitive.Content>
            </SelectPrimitive.Root>
            {formContext
                && formContext.errors
                && formContext.errors[name]
                && formContext?.errors[name]?.type === 'required' && (
                <FormAlert>Required</FormAlert>
            )}
        </div>
    );
};

export default Select;
